<template>
  <Lottery/>
</template>

<script>
import Lottery from './components/Lottery.vue'

export default {
  name: 'App',
  components: {
    Lottery
  }
}
</script>
