<template >
  <div class="mainSection ligth-mode">
    <header class="p-4 flex justify-center items-center mb-5" style="background-color: #ffb500;">
    `  <h1 class="text-lg font-medium">TOK TOK POINT - LOTTERY</h1>
    </header>
    <div class="container mx-auto">
      <div class="relative flex flex-wrap items-center mb-4 ml-4 mr-4">
        <label class="mr-2 mb-2">
          Жолоочийн нэр:
          <select class="bg-white focus:outline-none focus:shadow-outline-blue p-2 rounded-lg w-full md:w-1/4" v-model="rider">
            <option value="all">Бүгд</option>
            <option :value="bucket.key" v-for="bucket in lotteryResult?.aggs?.name?.buckets" :key="bucket.key">{{ bucket.key  }} ({{ bucket.doc_count  }} )</option>
          </select>
        </label>
        <label class="mr-2 mb-2">
          Сугалааны дугаар:
          <div class="flex items-center">
            <input v-model="firstNumber" class="bg-gray-300 text-black focus:outline-none focus:shadow-outline-blue p-4 rounded-lg  w-14 text-lg mr-1" placeholder="0" >
            <input v-model="secondNumber" class="bg-gray-300 text-black focus:outline-none focus:shadow-outline-blue p-4 rounded-lg  w-14 text-lg mr-1" placeholder="0" >
            <input v-model="thirdNumber" class="bg-gray-300 text-black focus:outline-none focus:shadow-outline-blue p-4 rounded-lg  w-14 text-lg mr-1" placeholder="0" >
            <input v-model="fourthNumber" class="bg-gray-300 text-black focus:outline-none focus:shadow-outline-blue p-4 rounded-lg  w-14 text-lg mr-1" placeholder="0" >
            <input v-model="fifthNumber" class="bg-gray-300 text-black focus:outline-none focus:shadow-outline-blue p-4 rounded-lg  w-14 text-lg mr-1" placeholder="0" >
            <input v-model="sixthNumber" class="bg-gray-300 text-black focus:outline-none focus:shadow-outline-blue p-4 rounded-lg  w-14 text-lg mr-1" placeholder="0" >
          </div>
        </label>
        <button class="text-white p-2 rounded-lg w-full md:w-1/4 h-14" style="background-color: #ffb500;" @click="searchLottery">Хайх</button>
      </div>
      <div class="mb-5">
        <span class="text-gray-600">Нийт <a class="text-green-600">{{ lotteryResult?.total?.value }}</a> ширхэг сугалааны дугаар байна.</span>
      </div>
      <table class="w-full border-collapse mx-auto">
        <thead>
          <tr class="bg-gray-200">
            <th class="p-2 font-medium text-left">№</th>
            <th class="p-2 font-medium text-left">Нэр</th>
            <th class="p-2 font-medium text-left">Сугалааны дугаар</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(lottery, index) in lotteryCodes" :key="lottery.code"  class="hover:bg-gray-100">
            <td class="p-2">{{ index + 1 }}</td>
            <td class="p-2">{{ lottery.name }}</td>
            <td class="p-2">{{ lottery.code }}</td>
          </tr>
        </tbody>
      </table>
    </div>`
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Lottery',
  data(){
      return{
        firstNumber: '',
        secondNumber: '',
        thirdNumber: '',
        fourthNumber: '',
        fifthNumber: '',
        sixthNumber: '',
        search: '??????',
        rider: 'all',
        size: 605,
        from: 0,
        lotteryResult:  {},
        lotteryCodes: []
      }
  },
  methods: {
    async searchLottery (){
      this.search = `${this.firstNumber || '?'}${this.secondNumber || '?'}${this.thirdNumber || '?'}${this.fourthNumber || '?'}${this.fifthNumber || '?'}${this.sixthNumber || '?'}`
      await this.getLotteryCodes()
    },
    async getLotteryCodes(){
      const response = await axios.post('https://ge85pcuj8e.execute-api.ap-southeast-1.amazonaws.com/prod/searchLotteryByCode', {
        size: this.size,
        from: this.from,
        rider: this.rider,
        search: this.search
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      this.lotteryResult = response.data.body
      this.lotteryCodes = response.data.body.data
    }
  },
  async mounted(){
    await this.getLotteryCodes()
  }
}
</script>

<style>
  table {
    background-color: var(--table-bg-color);
    color: var(--table-color);
  }
  table th {
    background-color: var(--header-bg-color);
    color: var(--header-color);
  }
</style>